import React, { useState } from 'react';
import Text from 'components/Core/Text';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import TextField from 'components/Core/TextField';
import { Col, Row } from 'components/Core/Grid';
import Button from 'components/Core/Button';
import { UtilityCalculatorContainer } from './styles';

const UtilityCalculator = ({ param1, param2, resultLabel, calculation }) => {
  const [isUpdate, setUpdate] = useState(false);
  const [values, setValues] = useState({
    [param1]: '',
    [param2]: '',
  });
  const [formValid, setFormValid] = useState({
    [param1]: 0,
    [param2]: 0,
  });
  const [results, setResults] = useState({
    [resultLabel]: '',
  });
  const checkFieldValidate = (id, value) => {
    const fieldValidation = formValid;
    fieldValidation[id] = value !== '' && value >= 0 ? 1 : -1;
    setFormValid(fieldValidation);
    setUpdate(!isUpdate);
    return fieldValidation[id];
  };
  const checkAllFieldValidate = () => {
    const keys = Object.keys(formValid);
    const stepValid = keys.filter(
      (element) => checkFieldValidate(element, values[element]) === 1,
    ).length;
    return stepValid === keys.length;
  };
  const handleChange = (id, value) => {
    setValues({ ...values, [id]: value });
    checkFieldValidate(id, value);
  };
  const calculate = () => {
    if (!checkAllFieldValidate()) {
      setResults({ [resultLabel]: '' });
      return;
    }
    setResults({
      [resultLabel]: calculation(values[param1], values[param2]),
    });
  };
  return (
    <UtilityCalculatorContainer>
      <Row gutter={[{ xs: 0, sm: 20 }, { xs: 0, sm: 20 }]}>
        <Col xs={24} sm={9} md={9} lg={9}>
          <Row gutter={[20, 20]}>
            <Col xs={16} sm={16} md={16} lg={16}>
              <FormLabelWithField isRequired label={`${param1}:`}>
                <TextField
                  showOnlyFormError
                  id={param1}
                  type="number"
                  values={values}
                  formValid={formValid}
                  handleChange={handleChange}
                />
              </FormLabelWithField>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Text className="utility-calculator--units-label" type="p" size="18px">
                Inches
              </Text>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={9} md={9} lg={9}>
          <Row gutter={[20, 20]}>
            <Col xs={16} sm={16} md={16} lg={16}>
              <FormLabelWithField isRequired label={`${param2}:`}>
                <TextField
                  showOnlyFormError
                  id={param2}
                  type="number"
                  values={values}
                  formValid={formValid}
                  handleChange={handleChange}
                />
              </FormLabelWithField>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Text className="utility-calculator--units-label" type="p" size="18px">
                Inches
              </Text>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={6} md={6} lg={6}>
          <Button
            className="utility-calculator--submit-button"
            type="secondary"
            onClick={calculate}
          >
            Calculate
          </Button>
        </Col>
      </Row>
      {results[resultLabel] != '' && (
        <Row>
          <div className="utility-calculator--result">
            <Text type="h6" size="20px">
              {resultLabel}:
            </Text>
            <Text type="p" size="18px">
              {results[resultLabel]} Inches
            </Text>
          </div>
        </Row>
      )}
    </UtilityCalculatorContainer>
  );
};

export default UtilityCalculator;
