import React from 'react';
import { graphql } from 'gatsby';
import Hero from 'components/Tools/Hero';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import ToolboxLayout from 'layouts/toolboxLayout';
import { HvacDuctForm } from 'components/Tools/HvacDuctCalculator/Form';
import UtilityCalculator from 'components/Tools/HvacDuctCalculator/UtilityCalculators';
import { findRectangle, findDiameter } from 'components/Tools/HvacDuctCalculator/calculation';
import ContentfulAccordion from 'components/Contentful/ContentfulAccordion';
import { UtilityFunctions } from 'components/Tools/HvacDuctCalculator/styles';
import BookmarkContext from 'utils/BookmarkContext';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';

const HvacDuctCalculator = (props) => {
  const data = props.data?.contentfulTool;
  const pathUrl = props.location;
  const breadcrumbs = createToolsBreadcrumbs('HVAC Duct Calculator');
  const faqProps = {
    backgroundColor: null,
    border: true,
    title: null,
    subhead: null,
    components: [
      {
        __typename: 'ContentfulFaq',
        answer: {
          answer: (
            <UtilityCalculator
              param1="Side 1"
              param2="Side 2"
              resultLabel="Diameter"
              calculation={findDiameter}
            />
          ),
        },
        question: { question: 'Convert Rectangular Duct to Round Equivalent' },
      },
      {
        __typename: 'ContentfulFaq',
        answer: {
          answer: (
            <UtilityCalculator
              param1="Diameter"
              param2="Side 1"
              resultLabel="Second Side"
              calculation={findRectangle}
            />
          ),
        },
        question: { question: 'Convert Diameter to Rectangular Equivalent' },
      },
    ],
  };

  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: props.path,
    type: 'tool',
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <Hero data={data.hero} />
        <HvacDuctForm gatedForm={data.gatedForm} pathUrl={pathUrl} />
        <UtilityFunctions id="utility-functions" className="hvac-duct--collapse-calculators">
          <ContentfulAccordion {...faqProps} />
        </UtilityFunctions>
        <DescriptionBlock data={data.description} />
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default HvacDuctCalculator;

export const HvacDuctCalculatorQuery = graphql`
  query HvacDuctCalculatorQuery {
    contentfulTool(internalName: { eq: "HVAC Duct Calculator" }) {
      ...Tool
    }
  }
`;
